(function() {
  const WebFontConfig = {
    google: {
      families: [
        "PT+Sans:400,400i,700,700i:latin-ext",
        "Taviraj:300,300i,400,400i,700,700i:latin-ext"
      ]
    }
  };
  let wf = document.createElement("script");
  wf.src =
    ("https:" == document.location.protocol ? "https" : "http") +
    "://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js";
  wf.type = "text/javascript";
  wf.async = "true";
  let s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(wf, s);
})();

(function() {
  const CLASSES = {
    MASONRY: "masonry",
    PANEL: "masonry-panel",
    PAD: "masonry-pad"
  };

  class Masonry {
    constructor(el) {
      this.container = el;
      this.panels = el.querySelectorAll(`.${CLASSES.PANEL}`);
      this.state = {};
      this.layout();
    }

    reset() {
      const { container } = this; //to samo co const container = this.container;

      this.state.heights = [];
      const filters = container.querySelectorAll(`.${CLASSES.PAD}`);
      if (filters.length) {
        for (let f = 0; f < filters.length; f++) {
          filters[f].parentNode.removeChild(filters[f]);
        }
      }
      container.removeAttribute("style");
    }

    populateHeights() {
      const { panels, state } = this;
      const { heights } = state;

      const viewportWidth = window.innerWidth; // + "px";
      for (let p = 0; p < panels.length; p++) {
        const panel = panels[p];

        if (viewportWidth >= 640) {
          if (p % 2 == 0) panel.style.order = 0;
          else panel.style.order = 1;
        } else panel.style.order = 0;

        const { order: cssOrder, msFlexOrder, height } = getComputedStyle(
          panel
        );
        const order = cssOrder || msFlexOrder;
        if (!heights[order - 1]) heights[order - 1] = 0;
        heights[order - 1] += parseFloat(height, 10);
      }
    }

    setLayout() {
      const { container, state } = this;
      const { heights } = state;
      this.state.maxHeight = Math.max(...heights);
      container.style.height = `${this.state.maxHeight}px`;
    }

    pad() {
      const { container } = this;
      const { heights, maxHeight } = this.state;
      heights.map((height, id) => {
        if (height < maxHeight && height > 0) {
          const pad = document.createElement("div");
          pad.className = CLASSES.PAD;
          pad.style.height = `${maxHeight - height}px`;
          pad.style.order = id + 1;
          pad.style.msFlexOrder = id + 1;
          container.appendChild(pad);
        }
      });
    }

    layout() {
      this.reset();
      this.populateHeights();
      this.setLayout();
      this.pad();
    }
  }

  if (document.querySelector(`.${CLASSES.MASONRY}`)) {
    window.offerMasonry = new Masonry(
      document.querySelector(`.${CLASSES.MASONRY}`)
    );

    window.addEventListener("resize", () => offerMasonry.layout());
  }

  const text = document.querySelectorAll(".text");
  if (text.length) {
    for (let f = 0; f < text.length; f++) {
      const newText = text[f].textContent.replace(
        /(\x20)(\(*[a-z])\x20/gi,
        "$1$2\u00A0"
      );
      text[f].textContent = newText;
    }
  }
})();

function initMap() {
  const styles = [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#424853"
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          visibility: "on"
        },
        {
          hue: "#00ff51"
        },
        {
          saturation: "-100"
        },
        {
          lightness: "0"
        }
      ]
    },
    {
      featureType: "landscape.man_made",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "poi.attraction",
      elementType: "all",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "poi.business",
      elementType: "all",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "all",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: 45
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on"
        },
        {
          color: "#d9d9d9"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.text",
      stylers: [
        {
          color: "#424853"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          visibility: "on"
        },
        {
          color: "#424853"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#a0a9b9"
        },
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#a0a9b9"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#424853"
        }
      ]
    }
  ];

  let mapContainer = document.querySelector("#googleMap");
  const myLatLng = {
    lat: Number(mapContainer.getAttribute("data-lat")),
    lng: Number(mapContainer.getAttribute("data-lng"))
  };

  let map = new google.maps.Map(mapContainer, {
    scaleControl: true,
    scrollwheel: false,
    zoom: 18,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    navigationControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
      style: google.maps.NavigationControlStyle.SMALL
    },
    center: myLatLng,
    styles: styles
  });

  const image = {
    url: "assets/images/icon-map.png",
    size: new google.maps.Size(40, 48),
    origin: new google.maps.Point(0, 0),
    scaledSize: new google.maps.Size(40, 48),
    anchor: new google.maps.Point(20, 48)
  };

  let marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: mapContainer.getAttribute("data-name"),
    icon: image
  });
}

const loadMap = function() {
  const script = document.createElement("script");
  script.src =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyB-RA-xsETj0ULsLUdNF7l7yVIFiBi0T7E&callback=initMap";

  script.defer = true;
  document.querySelector("body").append(script);
};



(function(){


const menuBar = document.querySelector('.main-menu-bar');
if(menuBar){
  let menuBarInfo = menuBar.getBoundingClientRect();
  let menuBarOffset = menuBar.offsetTop;
  // console.log(menuBarInfo.height);
  window.addEventListener("scroll", function(e){
      requestAnimationFrame(function(){
          if (window.pageYOffset > menuBarOffset){
            menuBar.classList.add('section-sticky')
            menuBar.parentNode.style.marginTop = `${menuBarInfo.height}px`;
          }
          else{
            menuBar.classList.remove('section-sticky')
            menuBar.parentNode.style.marginTop = `0`;
          }
      })
  })
  


}

//MENU
var url_location, // adres strony
hash_index_url_location, // polozenie # w adresie strony
hash_url_location, // nazwa # z adresu strony
url_clicked, // kliknieta pozycja menu
hash_index_url_clicked, // polozenie # w kliknietej pozycji menu
hash_url_clicked, // nazwa # z kliknietej pozycji menu
sections = ".section", // sekcje
mainMenu = "#main-menu",
menuToggle = "#main-menu-toggle",
topSection = ".section-top ",
navBar = ".header-content", //'#main-menu-wrapper' // pasek nagĹĂłwka
stickyNavHeight = 0, // dystans / wysokoĹÄ paska
scrollTop = 0, // przechowywanie aktualnej pozycji
didScroll = false, // jesli scroll
lastScrollTop = 0, // ostatnia pozycja scroll
sectionTopHeight = 0, // poĹoĹźenie od gĂłry pierwszej sekcji
currentPosition, // poĹoĹźenie scrollTop przed otworzeniem menu
mainMenuBg = "#main-menu-bg", // tlo do wyĹwietlania menu
top,
bottom,
colorSection,
oldColorSection,
//navBarTop,
goPosTop,
sectionsName = [],
isPortfolioLoaded = false;



// stickyNavHeight = Math.abs($(navBar).outerHeight()); //wysokoĹÄ paska #main-menu-wrapper
//   //$(navBar).parent('.always-scroll').parent().css('padding-top',stickyNavHeight);

//   //navBarTop = Math.abs($(navBar).offset().top); //od ktĂłrego miejsca bedzie fixed
//   sectionTopHeight = Math.abs($(topSection).outerHeight());

//   $(sections).each(function() {
//     sectionsName.push($(this).attr("id"));
//   });

//   url_location = normalize_url($(location).attr("href"));

//   hash_index_url_location = url_location.indexOf("#");

  // if (hash_index_url_location >= 0 && isPortfolioLoaded === false) {
  //   // jesli jest #

  //   hash_url_location = $(location).attr("hash");

  //   if ($(hash_url_location).length != 0) {
  //     // jesli jest # id
  //     $("html, body")
  //       .delay(100)
  //       .animate(
  //         { scrollTop: Math.round($(hash_url_location).offset().top) },
  //         1000
  //       );
  //   }
  // }

  // setActive();

  // setInterval(function() {
  //   if (didScroll) {
  //     hasScrolled();
  //     didScroll = false;
  //   }
  // }, 300);

  // $(window).scroll(function() {
  //   didScroll = true;
  //   setSticky();
  //   activeNav();

  //   var scroll = $(window).scrollTop();
  //   var speed = 0.5;

  //   $(".scroller").each(function() {
  //     var $this = $(this);
  //     var $parent = $this.parent();
  //     var topOffset = $parent.offset().top;
  //     var height = $parent.outerHeight(true);
  //     var parallaxSize = (scroll - topOffset) * speed;

  //     if (scroll > topOffset + height) {
  //       return;
  //     }

  //     $this.css({
  //       transform:
  //         scroll >= topOffset ? "translateY(" + parallaxSize + "px)" : ""
  //     });
  //   });
  // });

  const btnMenuTopOffers = document.querySelector(".btn-menu-top-offers");
  // function activeNav() {
  //   //aktywne pozycje menu przy przewijaniu
  //   scrollTop = $(window).scrollTop();

  //   if (
  //     !$(navBar)
  //       .parent()
  //       .hasClass("menu-active")
  //   ) {
  //     //brak wpĹywu wyĹwietlania menu na oznaczenie active w menu

  //     if (scrollTop < sectionTopHeight) {
  //       setActive();
  //     } else {
  //       $(sections).each(function() {
  //         (top = Math.round($(this).offset().top - stickyNavHeight)),
  //           (bottom = Math.round(top + $(this).outerHeight()));

  //         if (scrollTop >= top && scrollTop <= bottom) {
  //           $(mainMenu + " li").removeClass("active");
  //           $(sections).removeClass("active");
  //           $(this).addClass("active");
  //           if (
  //             ($(this).hasClass("bg-white") ||
  //               $(this).hasClass("bg-light_gray")) &&
  //             !$(navBar)
  //               .parent()
  //               .hasClass("always-scroll")
  //           ) {
  //             btnMenuTopOffers.classList.add("dark");
  //             $("#main-menu-toggle .open img").attr(
  //               "src",
  //               "images/open_light.svg"
  //             );
  //           } else {
  //             $("#main-menu-toggle .open img").attr("src", "images/open.svg");
  //             btnMenuTopOffers.classList.remove("dark");
  //           }
  //           $(mainMenu)
  //             .find('a[href$="#' + $(this).attr("id") + '"]')
  //             .parent()
  //             .addClass("active");
  //         }
  //       });
  //     }
  //   }
  // }

  // function hasScrolled() {
  //   scrollTop = $(window).scrollTop();

  //   if (Math.abs(lastScrollTop - scrollTop) <= stickyNavHeight) return;
  //   if (scrollTop > lastScrollTop && scrollTop > stickyNavHeight) {
  //     $(navBar)
  //       .parent()
  //       .removeClass("nav-down")
  //       .addClass("nav-up");

  //   } else {
  //     $(navBar)
  //       .parent()
  //       .removeClass("nav-up")
  //       .addClass("nav-down");

  //   }
  //   lastScrollTop = scrollTop;
  // }

  // function setActive() {
  //   $(mainMenu + " li").removeClass("active");
  //   $(mainMenu)
  //     .find('a[href$="' + url_location + '"]')
  //     .parent()
  //     .addClass("active");
  // }

  // function setSticky() {
  //   scrollTop = $(window).scrollTop();

  //   if (scrollTop >= sectionTopHeight) {
  //     $(navBar)
  //       .parent()
  //       .not(".menu-active")
  //       .addClass("sticky");
  //   } else {
  //     $(navBar)
  //       .parent()
  //       .not(".menu-active")
  //       .removeClass("sticky");
  //     if (
  //       $(navBar)
  //         .parent()
  //         .hasClass("always-scroll")
  //     ) {
   
  //     }
  //   }
  // }

  // $(menuToggle).on("click", function() {
  //   if (
  //     $(navBar)
  //       .parent()
  //       .hasClass("menu-active") &&
  //     $(navBar)
  //       .parent()
  //       .hasClass("menu-open")
  //   ) {
  //     $(mainMenu).animate({ opacity: 0 }, 200, function() {
  //       $(navBar)
  //         .parent()
  //         .removeClass("menu-open")
  //         .addClass("sticky");

  //       //if(currentPosition > $(navBar).outerHeight()-stickyNavHeight){
  //       console.log("currentPosition ".currentPosition);
  //       if (currentPosition > stickyNavHeight) {
  //         $(navBar)
  //           .parent()
  //           .addClass("nav-up");
  //       }

  //       //   $('html, body').scrollTop( currentPosition);
  //       //  $(mainMenuBg).animate({opacity: 0}, 500,function() {
  //       $(mainMenuBg).css("display", "none");
  //       $(mainMenu).css({ opacity: 1 });
  //       $(navBar)
  //         .parent()
  //         .removeClass("menu-active");
  //       $("body").removeClass("mobile-menu-show");
  //       // });
  //     });
  //   } // jeĹli menu zamkniete
  //   else if (
  //     $(navBar)
  //       .parent()
  //       .not(".menu-active")
  //       .not(".menu-open")
  //   ) {
  //     currentPosition = $(window).scrollTop();

  //     $("body").addClass("mobile-menu-show");
  //     $(navBar)
  //       .parent()
  //       .addClass("menu-active");
  //     // 0. aktualny scroll

  //     // 1. aktualna pozycja menu

  //     $(mainMenuBg).css("display", "block");


  //     $(navBar)
  //       .parent()
  //       .removeClass("sticky");

  //     $(navBar).css({ opacity: 0 });
  //     $(navBar).animate({ opacity: 1 }, 200);
  //     $(navBar)
  //       .parent()
  //       .addClass("menu-open");

  //   }
  // });

  // $(document).on("click", "#main-menu-bg", function(e) {
  //   $(mainMenu).animate({ opacity: 0 }, 200, function() {
  //     $(navBar)
  //       .parent()
  //       .removeClass("menu-open")
  //       .addClass("sticky");
  //     if (currentPosition > stickyNavHeight) {
  //       $(navBar)
  //         .parent()
  //         .addClass("nav-up");
  //     }
  //     $(mainMenuBg).css("display", "none");
  //     $(mainMenu).css({ opacity: 1 });
  //     $(navBar)
  //       .parent()
  //       .removeClass("menu-active");
  //     $("body").removeClass("mobile-menu-show");
  //   });
  // });

  const menuTopOffers = document.querySelector(".menu-top-offers");
  const body = document.querySelector("body");
  // if (btnMenuTopOffers && menuTopOffers) {
  //   function showMenuTopOffers() {
  //     menuTopOffers.classList.toggle("open");
  //     body.classList.toggle("menu-top-offers-show");
  //   }
  //   btnMenuTopOffers.addEventListener("click", showMenuTopOffers, false);
  // }

  // $(document).on("click", mainMenu + " a", function(e) {
  //   url_clicked = normalize_url($(this).attr("href"));
  //   hash_index_url_clicked = url_clicked.indexOf("#");

  //   if (hash_index_url_clicked >= 0) {
  //     // jesli jest # w kliknietym adresie
  //     url_location = normalize_url($(location).attr("href"));
  //     hash_url_clicked = url_clicked.substring(
  //       hash_index_url_clicked,
  //       url_clicked.length
  //     );

  //     url_clicked_without_hash = url_clicked.substring(
  //       hash_index_url_clicked,
  //       -1
  //     );
  //     url_location_without_hash = url_location.substring(
  //       hash_index_url_clicked,
  //       -1
  //     );

  //     if (url_clicked_without_hash == url_location_without_hash) {
  //       // jesli to ta sama strona

  //       e.stopPropagation();
  //       e.preventDefault();

  //       $("body").removeClass("mobile-menu-show");
  //       goPosTop = Math.round($(hash_url_clicked).offset().top);
  //       if (goPosTop < currentPosition) goPosTop = goPosTop - stickyNavHeight;

  //       if (
  //         $(navBar)
  //           .parent()
  //           .hasClass("menu-open")
  //       ) {
  //         $(mainMenu).animate({ opacity: 0 }, 500, function() {
  //           $(navBar)
  //             .parent()
  //             .removeClass("menu-open");

  //           if (currentPosition > $(navBar).outerHeight() - stickyNavHeight) {
  //             $(navBar)
  //               .parent()
  //               .addClass("sticky nav-up");
  //           }

  //           //$('html, body').scrollTop( currentPosition);
  //           //$(mainMenuBg).animate({opacity: 0}, 1000,function() {
  //           $(mainMenuBg).css("display", "none");

  //           $(mainMenu).css({ opacity: "" });

  //           $(mainMenu + " li").removeClass("active");
  //           $(mainMenu)
  //             .find('a[href$="' + hash_url_clicked + '"]')
  //             .parent()
  //             .addClass("active");

  //           if ($.inArray(hash_url_clicked, sectionsName)) {
  //             $("html, body").animate({ scrollTop: goPosTop }, 1000);
  //             $(navBar)
  //               .parent()
  //               .removeClass("menu-active");
  //           }

  //           // });
  //         });
  //       } else {
  //         $(mainMenu + " li").removeClass("active");
  //         $(mainMenu)
  //           .find('a[href$="' + hash_url_clicked + '"]')
  //           .parent()
  //           .addClass("active");

  //         if ($.inArray(hash_url_clicked, sectionsName)) {
  //           //if($(hash_url_clicked).length != 0){  // jesli jest # id
  //           $("html, body").animate({ scrollTop: goPosTop }, 1000);
  //         }
  //       }
  //     }
  //   } else {
  //     return true;
  //   }
  // });

  // $("#go-top").click(function() {
  //   $("body,html").animate({ scrollTop: 0 }, 1000);
  // });





})();

function normalize_url(url) {
  url = String(url);
  return url.replace(/\/?$/, "");
}